<template>
    <div class="videoPlay infinite-list-wrapper" v-scroll="loadMore">
        <THead :centerInfo="true"></THead>
        <div class="mauto">
            <div class="content">
                <div class="content_left">
                    <div class="information">
                        <div class="video_info">
                            <h4>{{listData.videoTitle}}</h4>
                            <span>{{listData.playNum}}播放量{{listData.bulletNum}}弹幕 {{listData.recDate}}</span>
                        </div>
                    </div>
                    <div class="videoPlay_box">
                        <div class="prism-player" ref="J_prismPlayer" id="J_prismPlayer"></div>
                        <!-- <div class="videoPlay_gift" v-if="giftboxInfo==true && giftshowQuee.giftPlayChannel==2">
                            <span class="giveUserName">{{giftshowQuee.username}}</span>
                            <span class="giveGift">{{listData.username}}</span>
                            <img :src="giftshowQuee.effectUrl" alt="">
                        </div>
                        <div class="videoPlay_smallgift" v-if="giftboxInfo==true && giftshowQuee.giftPlayChannel==1">
                            <span>{{giftshowQuee.username}}</span>
                            <span><em>赠送</em>{{listData.username}}</span>
                            <img :src="giftshowQuee.effectUrl" alt="">
                        </div> -->
                        <div class="videoPlayBigGift_box">
                            <!-- :style="'top:'+giftBigQueueIndex*-396+'px'" -->
                            <div class="videoPlayBigGift_hidebox" :style="'top:'+giftBigQueueIndex*-400+'px'">
                                <div class="videoPlay_gift"  v-for="(item,index) in giftBigQueue" :key="index" >
                                    <span class="giveUserName">{{item.username}}</span>
                                    <span class="giveGift">{{listData.username}}</span>
                                    <img :src="item.effectUrl" alt="">
                                </div>
                            </div>
                        </div>
              
                        <div class="videoPlaySmallGift_box">
                            <!-- {{}} -->
                            <div class="videoPlaySmallGift_hidebox" :style="'top:'+giftSmallQueueIndex*-74+'px'">
                             
                                <div class="videoPlay_smallgift" v-for="(item,index) in giftSmallQueue" :key="index" >
                                    <!-- {{}} -->
                                    <span>{{item.username}}</span>
                                    <span><em>赠送</em>{{listData.username}}</span>
                                    <img :src="item.effectUrl">
                                </div>
                            </div>  
                        </div>
                    </div>
                    <div class="comment">
                        <p>{{listData.playNum}}人观看</p>
                        <!-- <div>
                            <input :placeholder="userName==''?'':'请输入弹幕内容'" @keyup.enter="bulletFn" v-model="bulletText" type="" name="">
                            <p v-if="userName==''">请 <a
                                    :href="'http://passport.ourgame.com/lzgamelogin!webhallb.do?from=video&nexturl='+currentUrl">登录</a>
                                或 <a target="_blank" href="http://id.ourgame.com/regist.do?project=DATING">注册</a></p>
                            <button :class="userName==''?'notloginBg':'alredylogin'" @click="bulletFn"   placeholder="请输入弹幕" type="button">发送</button>
                        </div> -->
                    </div>
                    <!--点赞-->
                    <div class="functionKey">
                        <p @click="goodVideoFn">
                            <span :class="listData.isMyGood== 1?'aleadyClick':''" class="iconfont icon-dianzan"></span>
                            {{listData.goodNum}}
                        </p>
                        <p @click="collectVideoFn">
                            <span :class="listData.isMyCollect == 1?'aleadyClick':''"
                                class="iconfont icon-xingxing"></span>
                            {{listData.collectNum}}
                        </p>
                        <div class="forward">
                            <p>
                                <span class="iconfont icon-fenxiang"></span>
                                {{listData.transferNum}}
                            </p>
                            <div class="forward_box">
                                <div>
                                    <span>视频地址</span>
                                    <input readonly="readonly" id="videoUrl" type="" name="" :value="videoUrl">
                                    <button type="button" @click="copyText">复制</button>
                                </div>
                            </div>
                        </div>
                        <div class="price">
                            <el-popover placement="bottom" width="433px" trigger="click"
                                style="position:relative;padding:0px;height:113px;" v-model="visible" >
                                <div class="price_content">
                                    <img @click="giftDetailBannerL" class="btn_l" src="~assets/btn_left.png" alt="">
                                    <div class="price_box">
                                        <dl ref="giftDetail"
                                            :style="{'display': giftDetailBoxInfo==true?'block':'none'}">
                                            <dd><img :src="giftDetail.imageUrl" alt=""></dd>
                                            <dt>
                                                <p class="giftN">{{giftDetail.giftName}}</p>
                                                <p class="giftP">价格 <span>{{giftDetail.priceUser}}</span> <img
                                                        src="~assets/currency_icon.png" alt=""> </p>
                                                <span>拥有：{{giftDetail.myOwns}}</span>
                                            </dt>
                                        </dl>
                                        <div class="price_b">
                                            <ul ref="priceBannerBox">
                                                <li @mouseout="gifthide" @click="checkedGift(item,index)"
                                                    @mouseover="giftshow(item,index)" v-for="(item,index) in giftList" :key="index">
                                                    <img :class="giftCheckedIndex == item.giftId? 'checkedGift':''"
                                                        :src="item.imageUrl" alt="">
                                                </li>
                                            </ul>
                                        </div>
                                        <img @click="giftDetailBannerR" class="btn_r" src="~assets/btn_right.png"
                                            alt="">
                                        <div class="pricebtn_box">
                                            <button @click="presentGiftCoinFn">赏</button>
                                            <button @click="visible = false">取消</button>
                                        </div>
                                    </div>
                                </div>
                                <template #reference>
                                    <button CLASS="reward" type="button">赏</button>
                                </template>
                            </el-popover>


                        </div>
                    </div>
                    <!--评论-->
                    <div class="video_comment">
                        <div class="commentTitle">
                            <span>评论</span>
                        </div>
                        <div class="comment_content">
                            <!--输入框-->
                            <div class="comment_input">
                                <div class="user_xiu">
                                    <div id='' class="xiu_small userInputxiu">
                                        <img style="transform:scale(1)" v-if="userName == ''"
                                            src="~assets/userIcon.png" alt="">
                                    </div>
                                    <div class="xiu_box">
                                        <div class="xiu_max"></div>
                                        <ul>
                                            <li><img src="" alt=""></li>
                                            <li><img src="" alt=""></li>
                                            <li><img src="" alt=""></li>
                                            <li><img src="" alt=""></li>
                                            <li><img src="" alt=""></li>
                                            <li><img src="" alt=""></li>
                                            <li><img src="" alt=""></li>
                                            <li><img src="" alt=""></li>
                                            <li><img src="" alt=""></li>
                                        </ul>
                                    </div>
                                </div>
                                <!-- <div class="commentInput_box">
                                    <textarea v-model="commtUser.content" rows="" cols=""></textarea>
                                    <p class="notlogin" v-if="userName==''">请<a
                                            :href="'http://passport.ourgame.com/lzgamelogin!webhallb.do?from=video&nexturl='+currentUrl">登录</a>或<a
                                            target="_blank" href="http://id.ourgame.com/regist.do?project=DATING">注册</a>
                                    </p>
                                    <div class="emojis">
                                        <p> <img src="~assets/emial_icon.png" alt=""> <span>表情</span> </p>
                                        <emotion @emotion="handleEmotion" :height="200"></emotion>
                                    </div>
                                    <button :class="userName==''?'notloginBg':'alredylogin'"
                                        @click="commtFn(1,item)">发表评论</button>
                                </div> -->
                            </div>
                            <!-- 评论列表 一级 -->
                            <div class="commentHistoryList">
                                <div class="commentHistory_box" 
                                    v-for="(item,index) in videoComment1List" :key="index" v-if="videoComment1List.length!=0">
                                    <div class="commentHistory_parentbox">
                                        <div class="user_xiu">
                                            <div class="xiu_small " @mouseout="xiuHoverFn($event,false,1,item,index)"
                                                @mouseover="xiuHoverFn($event,true,1,item,index)"
                                                :class="item.username+'xiu'"></div>
                                            <div class="xiu_box" :ref="'xiuBox'+item.id" v-show="item.xiuInfo">
                                                <div>
                                                    <div class="xiu_max" :class="item.username+'xiu'"></div>
                                                    <ul>
                                                        <li v-for="(singItem,singIndex) in item.signArr" :key="singIndex"><img
                                                                :src="'http://bbs.lianzhong.com/images/privileges/'+singItem+'.gif'"
                                                                alt=""></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="commentInformation">
                                            <p class="userName">{{item.username}}</p>
                                            <p class="commentText"
                                                v-html="item.content.replace(/\#[\u4E00-\u9FA5]{1,3}\;/gi, emotion)">
                                            </p>
                                            <p> <em>{{item.recDate}}</em><em><span @click="good4CommentFn(item,index)"
                                                        :class="item.isMyGood==1?'aleadyClickcoomt':''"
                                                        class="iconfont icon-dianzan1"></span>{{item.goodNum}} </em>
                                                <em @click="commentreplyFn(1,index)">
                                                    <span class="iconfont icon-huifu"></span>回复</em>
                                                <em @click="reportFn(item)">
                                                    <span class="iconfont icon-jubao"></span>举报</em>
                                                <em @click="del4CommentFn(item,index,ind)"
                                                    v-if="userName == item.username">
                                                    <span class="iconfont icon-shanchu-01"></span>删除</em> </p>
                                        </div>
                                    </div>
                                    <!-- 评论列表二级 -->
                                    <div class="commentHistory_childrentotalBox" :ref="'childrentotalBox'+index">
                                        <div class="commentHistory_childrenbox" v-for="(ite,ind) in item.childrenArr" :key="ind">
                                            <div class="user_xiu">
                                                <div @mouseout="xiuHoverFn($event,false,2,ite,index,ind)"
                                                    @mouseover="xiuHoverFn($event,true,2,ite,index,ind)"
                                                    class="xiu_small" :class="ite.username+'xiu'"></div>
                                                <div class="xiu_box" :ref="'xiuBox'+ite.id" v-show="ite.xiuInfo">
                                                    <div>
                                                        <div class="xiu_max" :class="ite.username+'xiu'"></div>
                                                        <ul>
                                                            <li
                                                                v-for="(childrensingItem,childrensingIndex) in ite.signArr" :key="childrensingIndex">
                                                                <img :src="'http://bbs.lianzhong.com/images/privileges/'+childrensingItem+'.gif'"
                                                                    alt=""></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="commentInformation">
                                                <p class="userName">{{ite.username}}</p>
                                                <p class="commentText"
                                                    v-html="ite.content.replace(/\#[\u4E00-\u9FA5]{1,3}\;/gi, emotion)">
                                                </p>
                                                <p> <em>{{ite.recDate}}</em> <em><span
                                                            @click="good4CommentFn(ite,index,ind)"
                                                            :class="ite.isMyGood==1?'aleadyClickcoomt':''"
                                                            class="iconfont icon-dianzan1"></span>{{ite.goodNum}} </em>
                                                    <!-- <em @click="commentreplyFn(2,index,ind)">
                                                        <span class="iconfont icon-huifu"></span>回复</em> -->
                                                    <em @click="reportFn(ite)">
                                                        <span class="iconfont icon-jubao"></span>举报</em>
                                                    <em @click="del4CommentFn(ite,index,ind)"
                                                        v-if="userName == ite.username">
                                                        <span class="iconfont icon-shanchu-01"></span>删除</em>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <p v-if="item.moreInfo">共{{item.replyNum}}条回复， <span
                                            @click="clickmoreChildrenComment(item,index)">点击查看</span> </p>
                                    <el-pagination v-if="item.pageInfo" class="page" background="transparent"
                                        :currentPage="item.current"
                                        @current-change="handlechidldrenpageFn($event,index,item)"
                                        :page-size="item.size" next-text="下一页" layout="total, pager, next"
                                        :total="item.total">
                                    </el-pagination>
                                    <!-- :class="item.username+'xiu'"
:class="item.username+'xiu'" -->
                                    <div class="comment_input" v-show="item.comment">
                                        <div class="user_xiu">
                                            <div id='' class=" xiu_small" :class="$store.state.userName+'xiu'" >
                                            </div>
                                            <div class="xiu_box">
                                                <div class="xiu_max" ></div>
                                                <ul>
                                                    <li><img src="" alt=""></li>
                                                    <li><img src="" alt=""></li>
                                                    <li><img src="" alt=""></li>
                                                    <li><img src="" alt=""></li>
                                                    <li><img src="" alt=""></li>
                                                    <li><img src="" alt=""></li>
                                                    <li><img src="" alt=""></li>
                                                    <li><img src="" alt=""></li>
                                                    <li><img src="" alt=""></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <!-- <div class="commentInput_box">
                                            <textarea v-model="item.commentReplyContent" rows="" cols=""></textarea>
                                            <div class="emojis">
                                                <p> <img src="~assets/emial_icon.png" alt=""> <span>表情</span></p>
                                                <emotion @emotion="(i)=>{handleEmotion(i,index)}" :height="200"></emotion>
                                            </div>
                                            <button :class="userName==''?'notloginBg':'alredylogin'" @click="commtFn(2,item,index)">发表评论</button>
                                        </div> -->
                                    </div>
                                   

                                </div>

                            </div>
                            <!-- {{loadInfo}} -->
                             <div style="width:100%;text-align:center;padding:30px 0px;clear:both" v-if="loadInfo">加载中<img style="width:20px;height:20px;vertical-align:middle" src="~assets/loading.gif" alt=""></div>
                             <!-- <div style="width:100%;text-align:center;padding:30px 0px;clear:bot"></div> -->
                             <!-- {{loadmoreInfo}} -->
                             <div style="width:100%;text-align:center;padding:30px 0px;clear:both" v-if="!loadmoreInfo">没有更多了</div>
                             <!-- <div style="width:100%;text-align:center;padding:30px 0px;clear:both" v-if="loadmoreInfo">查看更多</div> -->
                        </div>
                    </div>
                </div>
                <!-- 右侧推荐列表 -->
                <div class="content_right">
                    <div class="video_up" @click="jumpUserVideo()" >
                        <div  id='' class="xiu_small xiudetailUserxiu">

                        </div>
                        <div class="user_info">
                            <p>{{listData.username}}</p>
                            <!-- <button type="button">关注</button> -->
                        </div>
                    </div>
                    <ul>
                        <li v-for="(item,index) in recommendLIst" :key="index"
                            @click="jump(item)">
                            <div class="cover_box">
                                <img :src="item.coverUrl" alt="">
                                <span></span>
                            </div>
                            <div class="video_introduce">
                                <p>{{item.videoTitle}}</p>
                                <span>{{item.username}}</span>
                                <em>{{item.playNum}}播放 {{item.bulletNum}}弹幕</em>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
   
    <!-- 举报原因 -->
    <div class="reportModal" v-show="reportQuery.info">
        <div>
            <textarea maxlength="40" name="" v-model="reportQuery.content" placeholder="请输入举报原因" id="" cols="30" rows="10"></textarea>
            <button @click="complain4CommentFn">举报</button>
            <button @click="cacelReport">取消</button>
        </div>
    </div>
    <!-- 打赏二次确认 -->
    <div class="modal" v-show="spendInfo">
        <img @click="closeModal" src="~assets/close_icon.png" alt="">
        <p>您是否要花费{{giftListQuery.memberPrice}}联众币，为作者打赏{{giftListQuery.giftName}}</p>
        <button @click="presentGiftIsCoinFn">确定</button>
        <button @click="closeModal">取消</button>
    </div>
    <!-- 联众币不足充值 -->
    <div class="modal" v-show="intoShopInfo">
        <img @click="closeModal" src="~assets/close_icon.png" alt="">
        <p class="lznoenough">
            您的联众币不足，无法打赏<br>
            建议您充值后打赏
        </p>

        <button class="blanka"><a href="https://newpay.ourgame.com/" target="_blank">前往充值</a></button>
        <button @click="closeModal">取消</button>
    </div>
    <!-- 删除评论 -->
    <div class="modal" v-show="deleteInfo">
        <img @click="closeModal" src="~assets/close_icon.png" alt="">
        <p>您确定是否要删除该条评论</p>
        <button @click="del4CommentFnHtpp">确定</button>
        <button @click="closeModal">取消</button>
    </div>
     </div>
</template>
<script>
    import THead from '@/components/head.vue';
    import Emotion from '@/components/Emotion/index'
    import axios from 'axios'
    import MugenScroll from "vue-mugen-scroll"
    import Vue from 'vue'
    import {
        getVideoPlayAuth,
        getVideoDetailByVideoID,
        bullet,
        getVideoBulletByVideoID,
        collectVideo,
        cancelCollectVideo,
        goodVideo,
        cancelGoodVideo,
        getPlayList2ByUsername,
        playVideo,
        transfer,
        getGifts,
        presentGiftCoin,
        getVideoGiftByVideoID,
        comment,
        getVideoComment1ByVideoID,
        good4Comment,
        getVideoComment2ByVideoID,
        complain4Comment,
        del4Comment,
        mask,
        presentGiftIsCoin
    } from '@/api/authority'
    export default {
        name: "videoPlay",
        data() {
            return {
                bulletText: '',
                danmukuList: [],
                listData: {},
                player: '',
                videoUrl: '',
                recommendLIst: [],
                giftList: [],
                visible:false,
                giftListQuery: { //发送礼物
                    giftID: '',
                    giftName: '',
                    giftNum: 1,
                    playParm1: '',
                    playParm2: '',
                    playParm3: '',
                    playParm4: '',
                    username: '',
                    videoID: '',
                    giftPlayChannel: ''
                },
                currentUrl:'',
                disabled: false,
                commtUser: { //评论参数
                    commentLevel: '',
                    content: '',
                    parentCommentID: '',
                    parentCommentUsername: '',
                    replyCommentID: '',
                    replyCommentUsername: '',
                    username: '',
                    videoID: '',
                    recDate: ''
                },
                videoComment1: { //1级评论参数
                    current: 1,
                    // size: 10,
                    size: 10,
                    videoID: '',
                },
                spendInfo: false,
                intoShopInfo: false,
                deleteInfo: false,
                videoComment1List: [],
                giftDetail: {},
                giftDetailBoxInfo: false,
                giftCheckedIndex: '',
                giftIndex: 0,
                giftleftIndex: 0,
                giftboxInfo: false,
                giveGiftList: [],
                myText: '',
                content: '',
                comment: '',
                settIme: '',
                giftqueue: [], //礼物队列
                giftSmallQueue:[],
                giftBigQueue:[],
                giftSmallQueueIndex:0,
                giftBigQueueIndex:0,
                giftqueueInfo: true,
                giftQueueIndex: 0,
                deleteIDcomment: 0,
                deletecommentLevel: 0,
                giftshowQuee: { //礼物显示
                    username: '',
                    imageUrl: '',
                    giftPlayChannel: ''
                },
                deleteIndex:'',
                raplychildrenType: "",
                raplychildrenInd: "",
                reportQuery: {
                    commentID: '',
                    content: '',
                    info: false,
                },
                loadmoreInfo:true,
                shopGiftInfo:true,
                noMore:false,
                loadInfo:true,
                userName:'',
                histRecordTime:'',
                giftTimeout:'',
                timeInfo:0,
                timeBigInfo:0,
                loadInfoShow:false,
            }
        },
        directives: {
            scroll: {
                bind (el, binding,vnode) {
                    var _this = this
               
                    window.addEventListener('scroll', function () {
                        // debugger
                        // Vue.$nextTick( () => {
                                let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
                                console.log('我滚动了---------------------------------------------------------------')
                                console.log(scrollTop + document.documentElement.clientHeight)
                                console.log(document.documentElement.scrollHeight-150)
                                console.log(scrollTop + document.documentElement.clientHeight >= document.documentElement.scrollHeight-150)
                                if (scrollTop + document.documentElement.clientHeight >= document.documentElement.scrollHeight-230) {
                                    // console.log('--------123123')
                                    let loadData = binding.value
                                    loadData()
                                }
                        // })
                        
                    })
            }
            }
        },
        mounted() {
            this.$nextTick(() => {
                window.scrollTo(0,0)
            })
            
            // debugger
            this.userName = this.$store.state.userName
            if (JSON.parse(localStorage.getItem('videorefeshInfo')) == true) {
                localStorage.setItem('videorefeshInfo', false)
            }
            this.currentUrl = 'http://'+window.location.host
            // this.watchSmallGift()
            this.$forceUpdate()

            this.videoUrl = location.href
            this.commtUser.videoID = this.$route.query.videoId
            this.videoComment1.videoID = this.$route.query.videoId



            // 获取视频详情
            this.getVideoDetailByVideo()

        
            // this.getbiaozhiFn()


        },
        destroyed() {


        },
        components: {
            THead,
            Emotion
        },
        watch: {
            giftBigQueue:{
                handler(val){
                    var _this = this
                    var time
                    if (val == '') {
                        return false
                    }
                    if(_this.timeBigInfo == 0){
                            time = setInterval(function(){
                                // console.log(_this.giftSmallQueue.length > _this.giftSmallQueueIndex)
                                if(_this.giftBigQueue.length>_this.giftBigQueueIndex && _this.giftBigQueue.length!=0){
                                    _this.giftBigQueueIndex++
                                    // console.log('bigindex值：')
                                    _this.timeBigInfo = 1
                                    // console.log(_this.giftBigQueueIndex)
                                }else{
                                    _this.timeBigInfo = 0
                                    clearInterval(time)
                                }
                            },3000)
                        }
                }
            },
            giftSmallQueue: {
              
                handler(val) {
                    var _this = this
                    var time
                    if (val == '') {
                        return false
                    }
                            //  var _this = this
                    if(_this.timeInfo == 0){
                        time = setInterval(function(){
                            // console.log(_this.giftSmallQueue.length > _this.giftSmallQueueIndex)
                            if(_this.giftSmallQueue.length>_this.giftSmallQueueIndex && _this.giftSmallQueue.length!=0){
                                _this.giftSmallQueueIndex++
                                // console.log('index值：')
                                _this.timeInfo = 1
                                // console.log(_this.giftSmallQueueIndex)
                            }else{
                                _this.timeInfo = 0
                                clearInterval(time)
                            }
                        },3000)
                    }

                    // if (this.giftqueueInfo) {
                    //     this.giftqueueInfo = false
                    //     _this.giftboxInfo = true
                    //     _this.giftshowQuee.username = val[_this.giftQueueIndex].username
                    //     _this.giftshowQuee.effectUrl = val[_this.giftQueueIndex].effectUrl
                    //     _this.giftshowQuee.giftPlayChannel = val[_this.giftQueueIndex].giftPlayChannel
                    //     setTimeout(function () {
                    //         _this.giftboxInfo = false
                    //     }, 2000)  
                    // }
                    // console.log('我触发监听了')
                    // var time =''
                    // _this.giftTimeout = setInterval(function () {
                    // time = setInterval(function () {
                        // console.log('------------------------------------------------------------------------------------------>')
                        // console.log('我走定时器了！！')
                        // setInterval(function (){
                        //     var num = 1
                        //     console.log(num++)
                        // },1000)
                        // if (_this.giftQueueIndex + 1 < val.length) {
                         
                        //     _this.giftQueueIndex++
                        //     _this.giftshowQuee.username = val[_this.giftQueueIndex].username
                        //     _this.giftshowQuee.effectUrl = val[_this.giftQueueIndex].effectUrl
                        //     _this.giftshowQuee.giftPlayChannel = val[_this.giftQueueIndex].giftPlayChannel
                        //     _this.giftboxInfo = true
                        //     console.log("我走播放礼物了")
                        //     console.log('当前第几个礼物：'+ _this.giftQueueIndex)
                        //     console.log('当前数组：'+ val)
                        //     console.log('队列里边礼物数量：'+ val.length)
                        // } else {
                        //     console.log('我关闭播放礼物了')
                        //     _this.giftboxInfo = false
                        //     // clearInterval(_this.giftTimeout)
                        //     clearInterval(time)
                        // }
                        // let num =0
                        // console.log(num++)
                    // }, 10000)
                },
                deep: true
            } 
        },
        beforeDestroy(){
            // 注销播放器
            this.player.dispose()
            
            // 关闭历史记录计时器
            clearInterval(this.histRecordTime)

            // 关闭礼物定时器
            clearInterval(this.giftTimeout)
            clearInterval(this.settIme)
            
        },
        methods: {
            watchSmallGift(){
                    // var time = ''
           
            },
            jumpUserVideo(){
                this.$router.push({
                    path: '/search',
                    query: {
                        vl: this.listData.username,
                        type:2
                    }
                })
            },
            jump(item){
                this.$store.commit('JUMP_PLAY',{alid:item.aliVideoId,videoId:item.videoID,vue:this})
            },

            async loadMore () {
                console.log('页面加载')
                console.log(this.loadInfo)
            //    debugger
            this.$nextTick(() => {
                if(this.loadInfo == true && this.loadInfoShow){
                     
                    if (this.noMore) {
                        this.loadInfo = false
                      return
                    }
                    this.loadInfo = true
                    this.videoComment1.current++
                    this.getVideoComment1ByVideoIDFn()
                }
            })

            },
            handleEmotion(i, index) {
                this.$store.dispatch('LOGIN_INFO', {
                    'vue': this,
                    'userName': this.$store.state.userName
                }).then(response => {
                    if (response) {
                        if (typeof index == 'undefined') {
                            this.commtUser.content += i
                        } else {
                            this.videoComment1List[index].commentReplyContent += i
                        }
                    }
                })
            },
            // 查询联众币是否充足
            presentGiftIsCoinFn() {
                this.giftListQuery.giftNum = 1
                if(!this.shopGiftInfo){
                    return false
                }
                this.shopGiftInfo = false
                presentGiftIsCoin(this.giftListQuery).then(response => {
                    this.spendInfo = false
                    if (response.data.code == 0) {
                        if (response.data.data.coinNum >= response.data.data.needCoinNum) {
                            presentGiftCoin(this.giftListQuery).then(response => {
                               
                                if (response.data.code == 0) {
                                    
                                    this.giftList[this.giftIndex].myOwns-- 
                                    var obj = Object.assign({},this.giftListQuery)
                                    this.giveGiftList.push(obj)
                                    if(this.giftListQuery.giftPlayChannel == 1){
                                        this.giftSmallQueue.push(obj)
                                    }else if(obj.giftPlayChannel == 2){
                                        this.giftBigQueue.push(obj)
                                    }
                                    this.shopGiftInfo = true
                                } else {
                                    this.$message({
                                        'message': response.data.data.msg
                                    })
                                }
                            })
                        } else {
                            this.intoShopInfo = true
                        }

                    } else { 

                    }
                })
            },
            // 评论屏蔽文字
            maskFn(text) {
                mask({
                    content: text
                }).then(response => {
                    if (response.data.code == 0) {
                        return response.data.data
                    } else {
                        this.$message({
                            'message': response.data.msg
                        })
                    }
                })
            },
            // 获取标志
            getbiaozhiFn(name, type, index, ind) {
                let _this = this
                axios.defaults.withCredentials = false
                axios.get(`${process.env.VUE_APP_API_xiu_URL}/query/getPrivilege?usernames=${encodeURI(name)}`).then(response => {
                    if (response.data.result == 1) {
                        var pv = response.data.content[0]
                        var num = []
                        for (var i = 0; i < pv.privilegeValue.length; i++) {
                            if (pv.privilegeValue[i] != 0) {
                                num.push(pv.privilegeValue[i])
                            }
                        }
                        // console.log(num)
                        if (type == 1) {
                            this.videoComment1List[index].signArr = num
                        } else {
                            this.videoComment1List[index].childrenArr[ind].signArr = num
                        }
                        //            for(var m = 0 ; m < num.length; m++){
                        //                 if(num[m]!=0){
                        //                     var html = '  <li><img src="http://bbs.lianzhong.com/images/privileges/'+num[m]+'.gif" alt=""></li>        '
                        //                         $('.xiu_left ul').append(html)
                        //                 }else{
                        //                     var html = '  <li><img src="" alt=""></li>'
                        //                     $('.xiu_left ul').append(html)
                        //                 }
                        //             } 
                        // }
                    }
                })

            },
            // 秀悬浮
            xiuHoverFn(e, info, type, item, index, ind) {
                //console.log('xiuBox' + item.id)
                // this.$refs['xiuBox'+item.id].style.left = e.pageX+'px'
                // this.$refs['xiuBox'+item.id].style.top = e.pageY+'px'
                // debugger
                if (info) {
                    this.getbiaozhiFn(item.username, type, index, ind)
                }

                if (type == 1) {
                    this.videoComment1List[index].xiuInfo = info
                } else {
                    this.videoComment1List[index].childrenArr[ind].xiuInfo = info
                }
            },
            // 举报
            reportFn(item) {
                this.$store.dispatch('LOGIN_INFO', {
                    'vue': this,
                    'userName': this.$store.state.userName
                }).then(response => {
                    if (response) {
                        this.reportQuery.commentID = item.id
                        this.reportQuery.info = true
                        this.reportQuery.content = ''
                    }
                })
            },
            complain4CommentFn() {
                complain4Comment(this.reportQuery).then(response => {
                    if (response.data.code == 0) {
                        this.$message({
                            'message': '举报成功'
                        })
                        this.reportQuery.info = false
                    } else {
                        this.$message({
                            'message': response.data.msg
                        })
                    }
                })
            },
            cacelReport() {
                this.reportQuery.info = false
            },
            // 删除评论
            del4CommentFn(item, index, ind) {
                debugger
                this.$store.dispatch('LOGIN_INFO', {
                    'vue': this,
                    'userName': this.$store.state.userName
                }).then(response => {
                    if (response) {
                        this.deleteInfo = true
                        this.deleteIDcomment = item.id
                        this.deletecommentLevel = item.commentLevel
                        this.deleteIndex = index
                        this.deleteChildrenIndex = ind
                    }
                })
            },
            del4CommentFnHtpp() {
                del4Comment({
                    commentID: this.deleteIDcomment
                }).then(response => {
                    if (response.data.code == 0) {
                        this.deleteInfo = false
                        if (this.deletecommentLevel == 1) {
                            this.videoComment1List.splice(this.deleteIndex, 1)
                        } else {
                            this.videoComment1List[this.deleteIndex].childrenArr.splice(this.deleteChildrenIndex, 1)
                        }
                        this.$message({
                            'message': '删除成功'
                        })
                        var xiuArr = []
                        for (let i in this.videoComment1List) {
                            xiuArr.push(this.videoComment1List[i].username)
                            for(let o in this.videoComment1List[i].childrenArr) {
                                 xiuArr.push(this.videoComment1List[i].childrenArr[o].username)
                            }
                        }

                        xiuArr = [...new Set(xiuArr)]
                       
                        this.$nextTick(() => {
                            this.$store.commit('GET_XIU', {
                                name: this.$store.state.userName,
                                dom: [`${this.$store.state.userName}`],
                                vue: this
                            })
                        })
                         this.$nextTick(() => {
                           this.$store.commit('GET_XIU', {
                            name: xiuArr.join(','),
                            dom: xiuArr,
                            vue: this
                           })
                        })
                    } else {
                        this.$message({
                            'message': response.data.msg
                        })
                    }
                })
            },
            // 评论回复
            commentreplyFn(type, index, ind) {
                this.$store.dispatch('LOGIN_INFO', {
                    'vue': this,
                    'userName': this.$store.state.userName
                }).then(response => {
                    if (response) {
                        if (type == 1) {
                            this.raplychildrenType = 1
                        } else {
                            this.raplychildrenType = 2
                            this.raplychildrenInd = ind
                        }
                        this.videoComment1List[index].comment = true;
                          this.$nextTick(() => {
                            this.$store.commit('GET_XIU', {
                                name: this.$store.state.userName,
                                dom: [`${this.$store.state.userName}`],
                                vue: this
                            })
                        })
                    }
                })
            },
            beforeunloadHandler(e) {
                e = e || window.event;
                if (e) {
                    alert("您是否确认离开此页面-您输入的数据可能不会被保存");
                }
                alert("您是否确认离开此页面-您输入的数据可能不会被保存");
            },
            // 评论点赞
            good4CommentFn(item, index, ind) {
                this.$store.dispatch('LOGIN_INFO', {
                    'vue': this,
                    'userName': this.$store.state.userName
                }).then(response => {
                    if (response) {
                        if (item.isMyGood == 1) {
                            return false
                        }
                        good4Comment({
                            commentID: item.id
                        }).then(response => {
                            if (response.data.code == 0) {
                                // console.log(typeof ind)
                                if (typeof ind == 'number') {
                                    this.videoComment1List[index].childrenArr[ind].isMyGood = 1
                                    this.videoComment1List[index].childrenArr[ind].goodNum++
                                    return false
                                }
                                this.videoComment1List[index].isMyGood = 1
                                this.videoComment1List[index].goodNum++
                            } else {
                                this.$message({
                                    'message': response.data.msg
                                })
                            }
                        })
                    }
                })
            },
            // 评论
            commtFn(type, item, index) {
                //     type 1 父级 2 子级
                //     type 1 父级 2 子级
                debugger
                this.commtUser.commentLevel = 1
                if (type == 2) {
                    this.commtUser.parentCommentID = item.id
                    this.commtUser.commentLevel = 2
                    this.commtUser.content = item.commentReplyContent
                }
                var defaultItem = {
                    childrenArr: [],
                    comment: false,
                    commentLevel: 1,
                    commentReplyContent: "",
                    content: "",
                    current: 1,
                    goodNum: 0,
                    id: '',
                    isComplain: 0,
                    isDeleted: 0,
                    isMyGood: 0,
                    moreInfo: false,
                    pageInfo: false,
                    parentCommentID: null,
                    parentCommentUsername: null,
                    recDate: "",
                    recIp: "",
                    replyCommentID: null,
                    replyCommentUsername: null,
                    replyNum: 0,
                    size: 10,
                    total: 1,
                    username: "",
                }
                this.commtUser.recDate = this.timestampToTime(new Date)
                if(this.commtUser.content == ''){
                     this.$message({
                        'message': '请输入内容'
                    })
                    return false
                }
                comment(this.commtUser).then(response => {
                    if (response.data.code == 0) {
                        Object.assign(defaultItem, this.commtUser)
                        // console.log(defaultItem)
                        defaultItem.username = this.$store.state.userName
                        defaultItem.id = response.data.data
                        defaultItem.xiuInfo = false
                        defaultItem.signArr = []
                        // console.log(this.maskFn(defaultIt/em.content))
                        // defaultItem.content = this.maskFn(defaultItem.content)
                        if (type == 1) {
                              mask({content: defaultItem.content}).then(response => {
                                if (response.data.code == 0) {
                                    // return response.data.data
                                    defaultItem.content = response.data.data
                                      this.videoComment1List.unshift(defaultItem)
                                      this.videoComment1List[index].comment = true;
                                 
                                      this.xiuAction()
                                } else {
                                    this.$message({
                                        'message': response.data.msg
                                    })
                                }
                            })
                          
                        } else {
                            mask({content: defaultItem.content}).then(response => {
                                if (response.data.code == 0) {
                                    // return response.data.data
                                    defaultItem.content = response.data.data
                                    this.videoComment1List[index].childrenArr.unshift(defaultItem)
                                    this.videoComment1List[index].commentReplyContent = ''
                                    this.videoComment1List[index].comment = false;
                                    this.xiuAction()
                                } else {
                                    this.$message({
                                        'message': response.data.msg
                                    })
                                }
                            })
                      
                        }
                       
                        if (type == 2) {

                        }
                    } else {
                        this.$message({
                            'message': response.data.msg
                        })
                    }
                })
            },
            xiuAction(){
                 this.commtUser.content = ''
                        // debugger
                        var xiuArr = []
                        for (let i in this.videoComment1List) {
                            xiuArr.push(this.videoComment1List[i].username)
                            for(let o in this.videoComment1List[i].childrenArr) {
                                 xiuArr.push(this.videoComment1List[i].childrenArr[o].username)
                            }
                        }
                        console.log(xiuArr)
                        xiuArr = [...new Set(xiuArr)]
                         console.log(xiuArr.join(','))
                        this.$nextTick(() => {
                            this.$store.commit('GET_XIU', {
                                name: this.$store.state.userName,
                                dom: [`${this.$store.state.userName}`],
                                vue: this
                            })
                        })
                        this.$nextTick(() => {
                           this.$store.commit('GET_XIU', {
                            name: xiuArr.join(','),
                            dom: xiuArr,
                            vue: this
                           })
                        })

            },
            load() {
                this.disabled = true
                this.videoComment1.current++
            },
            // 获取二级评论列表
            clickmoreChildrenComment(item, index) {
                if (item.replyNum > 3) {
                    this.videoComment1List[index].pageInfo = true
                    this.videoComment1List[index].moreInfo = false

                }
                this.getVideoComment2ByVideoIDFn(item, index)
            },
            getVideoComment2ByVideoIDFn(item, index) {
                getVideoComment2ByVideoID({
                    current: item.current,
                    parentCommentID: item.id,
                    size: item.size,
                    videoID: this.$route.query.videoId
                }).then(response => {
                    if (response.data.code == 0) {
                        this.videoComment1List[index].total = response.data.data.total
                        this.videoComment1List[index].childrenArr = response.data.data.records
                        var data = response.data.data.records
                        var xiuArr = []
                        for (let i in data) {
                            this.videoComment1List[index].childrenArr[i].xiuInfo = false
                            xiuArr.push(data[i].username)
                        }
                        this.$refs[`childrentotalBox${index}`].style.height = data.length * 79 + 'px'
                        xiuArr = [...new Set(xiuArr)]
                        // console.log(xiuArr)
                        // debugger
                        this.$nextTick(() => {
                           this.$store.commit('GET_XIU', {
                            name: xiuArr.join(','),
                            dom: xiuArr,
                            vue: this
                           })
                        })
                  
                    } else {
                        this.$message({
                            'message': response.data.msg
                        })
                    }
                })
            },
            handlechidldrenpageFn(val, index, item) {
                item.current = val
                this.getVideoComment2ByVideoIDFn(item, index)
            },
            // 获取一级评论列表
            getVideoComment1ByVideoIDFn() {
                this.videoComment1.token = this.$route.query.token
                this.loadInfo = false
                // this.loadmoreInfo = false
                getVideoComment1ByVideoID(this.videoComment1).then(response => {
                    if (response.data.code == 0) {
                        var data = response.data.data.records
                        this.loadInfo = true
                        // debugger
                        if(response.data.data.pages==response.data.data.current){
                            this.noMore = true
                            this.loadmoreInfo = false
                        }else{
                            this.noMore = false
                        }

                        if(response.data.data.records.length == 0 ){
                            this.loadmoreInfo = false
                            this.loadInfo = false
                        }
                        var arr = []
                        var xiuArr = []
                        var index = -1
                        var info = true
                        if(response.data.data.current>1){
                            // index = (((response.data.data.current-1)*10)+)-1
                        }
                        for (let i in data) {
                            xiuArr.push(data[i].username)
                            if (data[i].commentLevel == 1) {
                                arr.push(data[i])
                                    index++
                                arr[index].pageInfo = false //显示分页开关
                                arr[index].moreInfo = data[i].replyNum > 3 ? true : false //显示更多开关
                                arr[index].comment = false //评论开关
                                arr[index].total = 1 //评论开关
                                arr[index].current = 1 //评论开关
                                arr[index].size = 10 //评论开关
                                arr[index].commentReplyContent = '' //评论开关
                                arr[index].xiuInfo = false
                                arr[index].signArr = []
                                arr[index].childrenArr = []
                            
                            } else {
                               
                                if(response.data.data.current>1 &&this.videoComment1List[this.videoComment1List.length-1].id == data[i].parentCommentID ){
                                    this.videoComment1List[this.videoComment1List.length-1].childrenArr.push(data[i])
                                    // info = false
                                }else{
                                     arr[index].childrenArr.push(data[i])
                                }
                               
                            }
                        }

                        xiuArr = [...new Set(xiuArr)]
                        // debugger
                        // console.log(xiuArr)
                        this.$store.commit('GET_XIU', {
                            name: xiuArr.join(','),
                            dom: xiuArr,
                            vue: this
                        })
                        //console.log(arr)

                        this.videoComment1List.push(...arr)
                        console.log(this.videoComment1List)
                        if(response.data.data.current == 1){
                            this.loadInfoShow = true
                        }
                        // debugger
                        if (response.data.data.records.length != 0) {
                            this.disabled = false
                        }

                    } else {
                        this.$message({
                            'message': response.data.msg
                        })
                    }
                })
            },
            // 将匹配结果替换表情图片
            emotion(res) {
                let word = res.replace(/\#|\;/gi, '')
                const list = ['微笑', '撇嘴', '色', '发呆', '得意', '流泪', '害羞', '闭嘴', '睡', '大哭', '尴尬', '发怒', '调皮', '呲牙', '惊讶',
                    '难过', '酷',
                    '冷汗', '抓狂', '吐', '偷笑', '可爱', '白眼', '傲慢', '饥饿', '困', '惊恐', '流汗', '憨笑', '大兵', '奋斗', '咒骂', '疑问',
                    '嘘', '晕',
                    '折磨', '衰', '骷髅', '敲打', '再见', '擦汗', '抠鼻', '鼓掌', '糗大了', '坏笑', '左哼哼', '右哼哼', '哈欠', '鄙视', '委屈',
                    '快哭了', '阴险',
                    '亲亲', '吓', '可怜', '菜刀', '西瓜', '啤酒', '篮球', '乒乓', '咖啡', '饭', '猪头', '玫瑰', '凋谢', '示爱', '爱心', '心碎',
                    '蛋糕', '闪电',
                    '炸弹', '刀', '足球', '瓢虫', '便便', '月亮', '太阳', '礼物', '拥抱', '强', '弱', '握手', '胜利', '抱拳', '勾引', '拳头',
                    '差劲', '爱你',
                    'NO', 'OK', '爱情', '飞吻', '跳跳', '发抖', '怄火', '转圈', '磕头', '回头', '跳绳', '挥手', '激动', '街舞', '献吻', '左太极',
                    '右太极'
                ]
                let index = list.indexOf(word)
                return `<img src="https://res.wx.qq.com/mpres/htmledition/images/icon/emotion/${index}.gif" align="middle">`
            },
            // 查看礼物详情
            giftshow(item, index) {
                this.giftDetailBoxInfo = true
                this.$refs.giftDetail.style.left = ((index - this.giftleftIndex) * 53) - 34 + 'px'
                this.giftDetail = item
            },
            // 获取打赏列表
            getVideoGiftByVideoIDFn() {
                getVideoGiftByVideoID({
                    videoID: this.$route.query.videoId,
                    token : this.$route.query.token
                }).then(response => {
                    if (response.data.code == 0) {
                        this.giveGiftList = response.data.data
                    } else {
                        this.$message({
                            'message': response.data.msg
                        })
                    }
                })
            },
            closeModal() {
                this.spendInfo = false;
                this.intoShopInfo = false;
                this.deleteInfo = false;
            },
            // 赠送礼物
            presentGiftCoinFn() {
                if(this.giftCheckedIndex == ''){
                      this.$message({
                            'message': '请选择礼物'
                        })
                      return false
                }
                this.$store.dispatch('LOGIN_INFO', {
                    'vue': this,
                    'userName': this.$store.state.userName
                }).then(response => {
                    if (response) {
                        this.spendInfo = true
                        // this.presentGiftIsCoinFn()
                    }
                })
            },
            // 礼物banner
            giftDetailBannerR() {
                this.giftleftIndex++
                if (this.giftleftIndex + 7 <= this.giftList.length) {
                    this.$refs.priceBannerBox.style.left = -this.giftleftIndex * 52 + 'px'
                } else {
                    this.giftleftIndex--
                }
            },
            giftDetailBannerL() {
                this.giftleftIndex--
                if (this.giftleftIndex >= 0) {
                    this.$refs.priceBannerBox.style.left = -this.giftleftIndex * 52 + 'px'
                } else {
                    this.giftleftIndex++
                }
            },
            // 隐藏礼物详情
            gifthide(item) {
                this.giftDetailBoxInfo = false
            },
            // 选择礼物
            checkedGift(item, index) {
                // console.log(item)
                this.giftIndex = index
                this.giftCheckedIndex = item.giftId
                this.giftListQuery.giftID = item.giftId
                this.giftListQuery.giftName = item.giftName
                this.giftListQuery.effectUrl = item.effectUrl
                this.giftListQuery.memberPrice = item.memberPrice
                this.giftListQuery.priceUser = item.priceUser
                this.giftListQuery.giftPlayChannel = item.giftPlayChannel
                this.giftListQuery.username = this.$store.state.userName
                this.giftListQuery.videoID = this.$route.query.videoId
                this.giftListQuery.playParm1 = this.getcurrentTimeFn()
            },
            // 获取播放时间
            getcurrentTimeFn() {
                if (this.player) {
                    return parseInt(this.player.getCurrentTime()) * 1000
                }

            },
            // 右侧推荐播放列表
            getPlayList2ByUsernameFn() {
                getPlayList2ByUsername({
                    username: this.listData.username,
                    videoID: this.$route.query.videoId
                }).then(response => {
                    if (response.data.code == 0) {

                        this.recommendLIst = response.data.data
                    } else {
                        this.$message({
                            'message': response.data.msg
                        })
                    }
                })
            },
            // 获取礼物列表
            getGiftsFn() {
                getGifts( {token:this.$route.query.token}).then(response => {
                    if (response.data.code == 0) {
                        this.giftList = response.data.data
                        this.$refs.priceBannerBox.style.width = this.giftList * 55 + 'px'
                    } else {
                        this.$message({
                            'message': response.data.msg
                        })
                    }
                })
            },
            // 收藏
            collectVideoFn() {
                //isMyCollect 0未收藏 1已收藏
                this.$store.dispatch('LOGIN_INFO', {
                    'vue': this,
                    'userName': this.$store.state.userName
                }).then(response => {
                    if (response) {
                        if (this.listData.isMyCollect == 0) {
                            collectVideo({
                                videoID: this.$route.query.videoId
                            }).then(response => {
                                if (response.data.code == 0) {
                                    this.listData.isMyCollect = 1
                                    this.listData.collectNum++
                                } else {
                                    this.$message({
                                        'message': response.data.msg
                                    })
                                }
                            })
                        } else {
                            cancelCollectVideo({
                                videoID: this.$route.query.videoId
                            }).then(response => {
                                if (response.data.code == 0) {
                                    this.listData.isMyCollect = 0
                                    this.listData.collectNum--
                                } else {
                                    this.$message({
                                        'message': response.data.msg
                                    })
                                }
                            })
                        }
                    }
                })
            },
            // 点赞
            goodVideoFn() {
                //isMyGood 0未点赞 1已点赞
                this.$store.dispatch('LOGIN_INFO', {
                    'vue': this,
                    'userName': this.$store.state.userName
                }).then(response => {
                    if (response) {
                        if (this.listData.isMyGood == 0) {
                            goodVideo({
                                videoID: this.$route.query.videoId
                            }).then(response => {
                                if (response.data.code == 0) {
                                    this.listData.isMyGood = 1
                                    this.listData.goodNum++
                                } else {
                                    this.$message({
                                        'message': response.data.msg
                                    })
                                }
                            })
                        } else {
                            cancelGoodVideo({
                                videoID: this.$route.query.videoId
                            }).then(response => {
                                if (response.data.code == 0) {
                                    this.listData.isMyGood = 0
                                    this.listData.goodNum--
                                } else {
                                    this.$message({
                                        'message': response.data.msg
                                    })
                                }
                            })
                        }
                    }
                })

            },
            // 视频详情
            getVideoDetailByVideo() {
                getVideoDetailByVideoID({
                    videoID: this.$route.query.videoId,
                    token:this.$route.query.token
                }).then(response => {
                    if (response.data.code == 0) {
                        this.listData = response.data.data
                        this.getPlayList2ByUsernameFn()

                        // 一级评论
                        this.getVideoComment1ByVideoIDFn()
                      
                        // 获取打赏列表
                        this.getVideoGiftByVideoIDFn()
                        
                        // 获取播放凭证
                         this.getVideoPlayAuthFn()

                        // 上报视频播放
                        this.playVideoFn()
                        // 获取礼物列表
                        this.getGiftsFn()
                        // debugger
                        this.$store.commit('GET_XIU', {
                            name: this.listData.username,
                            dom: ["xiudetailUser"],
                            vue: this
                        })
                        if (this.$store.state.userName != '') {
                            this.$store.commit('GET_XIU', {
                                name: this.$store.state.userName,
                                dom: ["userInput"],
                                vue: this
                            })
                        }

                        // this.$store.commit('GET_XIU', {
                        //     name: this.listData.username,
                        //     dom: "xiudetail1",
                        //     vue: this
                        // })

                        //历史记录注册 
                        var histParm = []
                        var histInfo
                        var _this = this
                        var hisindex = 10
                        if (localStorage.getItem('historyVideo') != null) {
                            histParm = JSON.parse(localStorage.getItem('historyVideo'))
                        }
                        histInfo = histParm.filter((item, index) => {
                            if (item.videoID == this.$route.query.videoId) {
                                hisindex = index
                            }
                            return item.videoID == this.$route.query.videoId && item.userName == _this.$store.state.userName
                        })
                        // console.log('过滤---------')
                        // console.log(histInfo)
                        if (histInfo.length == 0) {
                            // 没有历史记录时
                            histParm.unshift({
                                "seeTime": new Date().getTime(),
                                "aliVideoId": _this.listData.aliVideoId,
                                "videoID": _this.$route.query.videoId,
                                "coverUrl": _this.listData.coverUrl,
                                "videoTitle": _this.listData.videoTitle,
                                "className": _this.listData.className,
                                "timeSlot": _this.getcurrentTimeFn(),
                                "userName": _this.$store.state.userName
                            })
                            //   console.log(histParm)
                            localStorage.setItem('historyVideo', JSON.stringify(histParm))
                            this.histRecordTime = setInterval(function () {
                                histParm[0].timeSlot = _this.getcurrentTimeFn()
                                histParm[0].seeTime = new Date().getTime()
                                // console.log(histParm[0].videoTitle)
                                // histParm[0].userName = this.$store.state.userName
                                // console.log('无历史记录-----------------------------')
                                // console.log(histParm)
                                // histParm = histParm.reverse()
                                localStorage.setItem('historyVideo', JSON.stringify(histParm))
                            }, 1000)
                        } else {
                            // 有历史记录
                            let arr =  Object.assign({},histParm[hisindex]) 
                            if(histParm.length!=1){
                                  histParm.splice(hisindex,1)
                            }
                            histParm.unshift(arr)
                            this.histRecordTime = setInterval(function () {
                                histParm[0].timeSlot = _this.getcurrentTimeFn()
                                histParm[0].seeTime = new Date().getTime()
                                // console.log(_this.$store.state.userName)
                                histParm[0].userName = _this.$store.state.userName
                                // console.log('有历史记录---------------------------')
                                // console.log(histParm)
                                // histParm = histParm.reverse()
                                localStorage.setItem('historyVideo', JSON.stringify(histParm))
                                 
                            }, 1000)
                        }
                    } else {
                        this.$message({
                            'message': response.data.msg
                        })
                    }
                })
            },
            // 弹幕字体大小随机数
            randomNum(minNum, maxNum) {
                switch (arguments.length) {
                    case 1:
                        return parseInt(Math.random() * minNum + 1);
                        break;
                    case 2:
                        return parseInt(Math.random() * (maxNum - minNum + 1) + minNum);
                        break;
                    default:
                        return 0;
                        break;
                }
            },
            //随机生成十六进制颜色
            randomHexColor() {
                return ('00000' + (Math.random() * 0x1000000 << 0).toString(16)).substr(-6);
            },
            // 上报分享
            transferFn() {
                transfer({
                    videoID: this.$route.query.videoId
                }).then(response => {
                    if (response.data.code == 0) {

                    } else {
                        this.$message({
                            'message': response.data.msg
                        })
                    }
                })
            },
            // 复制文字到剪切板
            copyText() {
                this.transferFn()
                var Url2 = document.getElementById("videoUrl").value;
                var oInput = document.createElement("input");
                oInput.value = Url2;
                document.body.appendChild(oInput);
                oInput.select(); // 选择对象
                document.execCommand("Copy"); // 执行浏览器复制命令
                oInput.className = "oInput";
                oInput.style.display = "none";
                 this.$message({
                    'message': '复制成功'
                })
            },
            // 上报视频播放
            playVideoFn() {
                playVideo({
                    videoID: this.$route.query.videoId
                }).then(response => {
                    if (response.data.code == 0) {

                    } else {
                        this.$message({
                            'message': response.data.msg
                        })
                    }
                })
            },
            // 获取播放凭证
            getVideoPlayAuthFn() {
                getVideoPlayAuth({
                    videoId: this.$route.query.al
                }).then(response => {
                    if (response.data.code == 0) {
                        this.getVideoBulletFn(response.data.data)
                    } else {
                        this.$message({
                            'message': response.data.msg
                        })
                    }
                })
            },
            // 获取弹幕列表
            getVideoBulletFn(parm) {
                getVideoBulletByVideoID({
                    videoID: this.$route.query.videoId,
                    token:this.$route.query.token
                }).then(response => {
                    if (response.data.code == 0) {
                        var obj = {}
                        var data = response.data.data
                        var _this = this
                        this.danmukuList = []
                        for (let i in data) {
                            obj = {
                                "mode": parseInt(data[i].playParm3),
                                "text": data[i].content,
                                "stime": parseInt(data[i].playParm1),
                                "size": parseInt(data[i].playParm4),
                                "color": data[i].playParm2
                            }
                            this.danmukuList.push(obj)
                        }
                        if(this.giveGiftList.length!=0){
                            this.danmukuList.unshift({
                                 "mode": 1,
                                "text": '该条视频收到了'+this.giveGiftList.length+'个礼物',
                                "stime": 1000,
                                "size": 40,
                                "color": _this.randomHexColor()
                            })
                        }
                        this.createVideo(parm)
                    } else {
                        this.$message({
                            'message': response.data.msg
                        })
                    }
                })
            },
            // 弹幕
            bulletFn() {
                if (this.bulletText != '') {
                    var _this = this
                    var time = _this.getcurrentTimeFn()
                    var parm = {
                        content: _this.bulletText,
                        username: this.$store.state.userName,
                        videoID: this.$route.query.videoId,
                        playParm1: time,
                        playParm2: _this.randomHexColor(),
                        playParm3: 1,
                        playParm4: 20,
                    }
                    
                    bullet(parm).then(response => {
                        if (response.data.code == 0) {
                             mask({content: _this.bulletText}).then(response => {
                                if (response.data.code == 0) {
                                    // return response.data.data
                                    // defaultItem.content = response.data.data
                                    // this.videoComment1List[index].childrenArr.unshift(defaultItem)
                                    // this.videoComment1List[index].commentReplyContent = ''
                                       this.player.getComponent('AliplayerDanmuComponent').send({
                                        "mode": 1,
                                        "text": response.data.data,
                                        "stime": time,
                                        "size": 20,
                                        "color": _this.randomHexColor()
                                    })
                                        this.bulletText = ''
                                } else {
                                    this.$message({
                                        'message': response.data.msg
                                    })
                                }
                            })
                         
                        } else {
                            this.$message({
                                'message': response.data.msg
                            })
                        }
                    })
                }
            },
            histRecor(){
                    let arr = []
                    arr = JSON.parse(localStorage.getItem('historyVideo')).reverse().filter((item,index) => {
                            return item.userName == this.$store.state.userName && item.videoID==_this.$route.query.videoId
                    })
                    return arr
            },
            // 初始化播放控件
            createVideo(data) {
                var _this = this
                // this.player = ''
                // this.$refs. .innerHTML = ''
                this.player = new Aliplayer({
                    id: 'J_prismPlayer',
                    width: '100%',
                    height: '608px;',
                    autoplay: 'true',
                    playauth: data.playAuth,
                    encryptType: 1, //当播放私有加密流时需要设置。
                    vid: data.aliVideoId,
                    cover: data.coverUrl,
                    isLive: false,
                    controlBarVisibility: 'hover',
                    showBarTime: '10000',

                    "extraInfo": {
                        "crossOrigin": "anonymous"
                    },
                    "skinLayout": [{
                            "name": "bigPlayButton",
                            "align": "blabs",
                            "x": 30,
                            "y": 80
                        },
                        {
                            "name": "H5Loading",
                            "align": "cc"
                        },
                        {
                            "name": "errorDisplay",
                            "align": "tlabs",
                            "x": 0,
                            "y": 0
                        },
                        {
                            "name": "infoDisplay"
                        },
                        {
                            "name": "tooltip",
                            "align": "blabs",
                            "x": 0,
                            "y": 56
                        },
                        {
                            "name": "thumbnail"
                        },
                        {
                            "name": "controlBar",
                            "align": "blabs",
                            "x": 0,
                            "y": 0,
                            "children": [{
                                    "name": "progress",
                                    "align": "blabs",
                                    "x": 0,
                                    "y": 44
                                },
                                {
                                    "name": "playButton",
                                    "align": "tl",
                                    "x": 15,
                                    "y": 12
                                },
                                {
                                    "name": "timeDisplay",
                                    "align": "tl",
                                    "x": 10,
                                    "y": 7
                                },
                                {
                                    "name": "fullScreenButton",
                                    "align": "tr",
                                    "x": 10,
                                    "y": 12
                                },
                                {
                                    "name": "subtitle",
                                    "align": "tr",
                                    "x": 15,
                                    "y": 12
                                },
                                {
                                    "name": "setting",
                                    "align": "tr",
                                    "x": 15,
                                    "y": 12
                                },
                                {
                                    "name": "volume",
                                    "align": "tr",
                                    "x": 5,
                                    "y": 10
                                },
                                {
                                    "name": "snapshot",
                                    "align": "tr",
                                    "x": 10,
                                    "y": 12
                                }
                            ]
                        }
                    ],
                    components: [
                        // 弹幕
                        {
                            name: 'AliplayerDanmuComponent',
                            type: AliPlayerComponent.AliplayerDanmuComponent,
                            args: [_this.danmukuList]
                        },
                        // 倍速

                        {
                            name: 'RateComponent',
                            type: AliPlayerComponent.RateComponent
                        },
                        // 清晰度
                        {
                            name: 'QualityComponent',
                            type: AliPlayerComponent.QualityComponent
                        },
                    ],
                }, function (player) {
                    // player.on('playing', function () {
                        var time = ''
                        // console.log()
                        _this.giftTimeout = setInterval(function () {
                            // console.log(_this.giveGiftList)
                            for (let i in _this.giveGiftList) {
                                if (_this.getcurrentTimeFn()  == _this.giveGiftList[i].playParm1  && player.getStatus()!='pause') {
                                    if(player.getStatus()=="playing"){
                                        if(_this.giveGiftList[i].giftPlayChannel==1){
                                            _this.giftSmallQueue.push(_this.giveGiftList[i])
                                        }else{
                                            _this.giftBigQueue.push(_this.giveGiftList[i])
                                        } 
                                    }
                                
                                }
                            }
                        }, 1000)
                        // player.on('pause', function () {
                            // console.log('我暂停了')
                            // console.log(time)
                        //    clearInterval(_this.giftTimeout)
                        // })
                    // })
         
                    // player.on('timeupdate', function () {
                        // console.log(_this.getcurrentTimeFn())
                        // _this.settIme = setInterval(function () {
                        //     for (let i in _this.giveGiftList) {
                        //         if (_this.getcurrentTimeFn() - 2 == _this.giveGiftList[i]
                        //             .playParm1 - 2) {
                        //             // debugger
                        //             _this.giftqueue.push(_this.giveGiftList[i])
                        //         }
                        //     }
                        // }, 1000)
                    // }) 

                    //来源历史记录 跳转上次观看时间 
                    if(JSON.parse(localStorage.getItem('historyVideo'))!=null){
                            let history = ''
                            history = JSON.parse(localStorage.getItem('historyVideo')).reverse().filter((item,index) => {
                                    return item.userName == _this.$store.state.userName && item.videoID==_this.$route.query.videoId
                            })
                            player.seek(history[0].timeSlot / 1000);
                    }

                    player.on('ended', function () {
                        _this.giftqueueInfo = true
                        _this.giftqueue = []
                        _this.giftQueueIndex = 0
                        // debugger
                        clearTimeout(_this.settIme)
                    })

                    player.on('snapshoted', function (data) {
                        var pictureData = data.paramData.base64
                        var downloadElement = document.createElement('a')
                        downloadElement.setAttribute('href', pictureData)
                        var fileName = 'Aliplayer' + Date.now() + '.png'
                        downloadElement.setAttribute('download', fileName)
                        downloadElement.click()
                        pictureData = null
                    })

                });
            }
        }
    }
</script>
<style lang="less">
    .prism-player .prism-big-play-btn {
        z-index: 99;
    }

    .ali-danmuku-control .danmu-input {
        display: none !important
    }
    .prism-setting-btn{
        display: none;
    }
.btnBG(){
  background-color: #ff8a00;
  color:white;
  font-size: 14px;
}
    .videoPlay::-webkit-scrollbar {
        width: 0 !important
    }

    .notloginBg {
        background: #f4f4f4 !important;
        color: #b8c0cc !important
    }
    .alredylogin:hover{
        background: #ff6600 !important;
    }

    .el-popper__arrow {
        display: none;
    }

    .price_content {
        // display: none;
        // position: absolute;
        // top: 22px;
        // left: 0px;
        width: 433px;
        height: 91px;
        // padding-top: 23px;
        box-sizing: border-box;
        background-color: #ffffff;
        // box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.21);
        border-radius: 4px;
        // border: solid 1px #e7e7e7;
        z-index: 9;

        >img {
            cursor: pointer;
            position: relative;
            top: 0px;
            left: 0px;
            z-index: 999
        }

        .btn_l {
            float: left;
            padding-left: 10px;
            padding-right: 10px;
        }

        .btn_r {
            float: left;
            padding-left: 10px;
        }

        .pricebtn_box {
            clear: both;
            width: 104px;

            margin: 0px auto;
            padding-top: 20px;

            button {
                color: white;
                width: 37px;
                height: 25px;
                background-color: #ff8a00;
                border-radius: 13px;
                float: left;
                position: relative;
                top: 0px;
                left: 0px;
                z-index: 999
            }

            button+button {
                width: 55px;
                height: 25px;
                background-color: #00a1d6;
                border-radius: 13px;
                margin-left: 11px;
            }
        }

        .price_box {
            position: relative;
            top: 0px;
            left: 0px;
            cursor: pointer;

            dl {
                position: absolute;
                top: -77px;
                left: -34px;
                width: 175px;
                height: 72px;
                background: url(../assets/minpricemodal_bg.png);
                padding: 6px 0px 0px 7px;
                box-sizing: border-box;
                display: none;

                dd {
                    img {
                        float: left;
                        width: 55px;
                        height: 55px;
                        border-radius: 4px;
                        overflow: hidden;
                        padding-right: 4px;
                    }
                }

                dt {
                    padding-left: 4px;

                    .giftN {
                        font-size: 14px;
                    }

                    .giftP {
                        font-size: 12px;
                        padding-top: 10px;

                        span {
                            color: #ff8a00;
                        }

                        img {
                            vertical-align: middle;
                        }
                    }

                    >span {
                        color: #959595;
                        font-size: 12px;
                        display: block;
                    }
                }
            }

            .price_b {
                width: 364px;
                height: 44px;
                overflow: hidden;
                display: block;
                float: left;
                position: relative;
                top: 0px;
                left: 0px;

                ul {
                    width: 1000px;
                    position: relative;
                    left: 0px;
                    top: 0px;
                    transition: all .5s ease-in-out;
                    margin-left: -4px;

                    li {
                        float: left;
                        // margin-left: 9px;
                        margin:0px 4px;
                        cursor: pointer;
                        position: relative;
                        top: 0px;
                        left: 0px;

                        >img {
                            width: 44px;
                            height: 44px;
                            border-radius: 4px;
                            overflow: hidden;
                            border: 1px solid transparent;
                            box-sizing: border-box;
                        }

                        .checkedGift {
                            border: 3px solid #ff8a00;
                        }
                    }
                }
            }
        }
    }

    .videoPlay {
        padding-top: 71px;
        // height: 1000px;
        background: white !important;
        // overflow: auto;
        // padding-bottom: 20px;

        .xiu_small {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            float: left;
            overflow: hidden;
            position: relative;

            img {
                transform: scale(.38);
                transform-origin: top left;
            }

        }

        .mauto {
            .information {
                height: 90px;

                .video_info {
                    float: left;

                    h4 {
                        color: #333333;
                        font-size: 18px;
                        padding: 25px 0px 12px 0px;
                    }

                    span {
                        color: #b8b8b8;
                        font-size: 12px;
                    }
                }


            }

            .content {
                clear: both;

                .content_left {
                    float: left;
                    width: 870px;

                    .videoPlay_box {
                        position: relative;
                        top: 0px;
                        left: 0px;
                        width: 100%;
                        height: 608px;
                        margin-bottom: 8px;
                        .videoPlaySmallGift_box{
                            position: absolute;
                            display: block;
                            top: 340px;
                            right: 6px;
                            width:290px;
                            // background:red;
                            height:219px;
                            overflow: hidden;
                        }
                        .videoPlaySmallGift_hidebox{
                            position: relative;
                            top:0px;
                            left:0px;
                            animation: 1s infinite;
                        }
                        .videoPlay_smallgift {
                        
                            position: relative;
                            top:0px;
                            left:0px;
                            span {
                                position: absolute;
                                top: 19px;
                                left: 65px;
                                color: #2b4152;
                                font-size: 12px;
                            }

                            span+span {
                                top: 38px;

                                em {
                                    color: #ff6b6b;
                                }
                            }
                        }
                        .videoPlayBigGift_box{
                              position: absolute;
                            //   display: none;
                            top: 73px;
                            width: 290px;
                            height: 396px;
                            overflow: hidden;
                            left: 283px;
                        }
                        .videoPlayBigGift_hidebox{
                            position: relative;
                            top:0px;
                            left:0px;

                        }
                        .videoPlay_gift {
                            // position: absolute;
                            // //   display: none;
                            // top: 73px;
                            // left: 283px;

                               position: relative;
                            top:0px;
                            left:0px;
                            span {
                                color: white;
                                width: 120px;
                                height: 34px;
                                display: block;
                                line-height: 34px;
                                position: absolute;
                                top: 362px;
                                z-index: 993;
                                text-align: center;
                            }

                            .giveGift {
                                left: 170px;
                            }

                            >img {
                                // position: absolute;
                                // top: 0px;
                                // left: 0px;
                                z-index: 992;
                                width: 290px;
                                height: 396px;
                            }
                        }

                        .prism-player {
                            width: 100%;
                            height: 608px;
                            position: relative;
                            top: 0px;
                            left: 0px;

                        }
                    }


                    .comment {
                        height: 46px;

                        border-bottom: 1px solid #ebedf3;

                        >p {
                            float: left;
                            color: #6c6c6c;
                            font-size: 12px;
                            line-height: 46px;
                            text-indent: 15px;
                        }

                        >div {
                            float: right;
                            position: relative;
                            top: 6px;
                            left: 0px;
                            margin-right: 16px;

                            input {
                                width: 370px;
                                height: 30px;
                                line-height: 30px;
                                background-color: #f4f4f4;
                                border-radius: 2px;
                                text-indent: 8px;
                                border: solid 1px #e7e7e7;
                            }

                            p {
                                position: absolute;
                                top: 0px;
                                left: 0px;
                                width: 370px;
                                line-height: 30px;

                                height: 30px;
                                text-indent: 8px;
                                font-size: 12px;
                                color: #b8b8b8;

                                a {
                                    color: #00a1d6;
                                }
                            }

                            button {
                                .btnBG();
                                width: 66px;
                                height: 30px;
                                border-radius: 0px 4px 4px 0px;
                            }
                        }
                    }

                    .aleadyClickcoomt {
                        color: #ff8a00
                    }

                    .functionKey {
                        padding-top: 15px;

                        .aleadyClick {
                            color: #ff8a00
                        }


                        .forward {
                            float: left;
                            padding-left: 53px;
                            position: relative;

                            &:hover .forward_box {
                                display: block
                            }

                            &:hover .icon-fenxiang {
                                color: #ff8a00
                            }

                            .forward_box {
                                display: none;
                                width: 433px;
                                height: 80px;
                                box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.21);
                                position: absolute;
                                top: 32px;
                                left: -120px;
                                border-radius: 4px;
                                z-index: 99999;
                                background: white;
                                padding-left: 19px;
                                color: #757575;
                                box-sizing: border-box;
                                border: solid 1px #e7e7e7;

                                >div {
                                    padding-top: 24px;
                                    clear: both;

                                    span {
                                        padding-right: 17px;
                                        line-height: 30px;
                                        display: inline-block;
                                        font-size: 12px;
                                    }

                                    input {
                                        width: 260px;
                                        height: 30px;
                                        background-color: #f4f4f4;
                                        border-radius: 2px;
                                        border: solid 1px #e7e7e7;
                                        vertical-align: top;
                                        text-indent: 6px;
                                    }

                                    button {
                                        width: 66px;
                                        height: 31px;
                                        background-color: #ff8a00;
                                        font-size: 12px;
                                        color: white;
                                        border-radius: 0px 4px 4px 0px;
                                    }
                                }

                                >p {
                                    padding: 20px 0px 0px 12px;
                                    transition: all 1s;

                                    >span {
                                        float: left;
                                        padding-top: 12px;


                                    }

                                    >em {
                                        font-style: normal;
                                        padding-left: 25px;
                                        float: left;
                                        width: 30px;
                                        text-align: center;
                                        line-height: 12px;

                                        span {
                                            display: inline-block;
                                            width: 30px;
                                            font-size: 18px;
                                            line-height: 31px;
                                            cursor: pointer;
                                            background-color: #9a9a9a;
                                            border-radius: 50%;
                                            overflow: hidden;
                                            color: white;
                                        }

                                        display: inline-block;
                                    }
                                }
                            }

                            >p {
                                color: #757575;
                                cursor: pointer;
                                line-height: 26px;
                                height: 36px;

                                span {
                                    display: inline-block;
                                    // margin-right:6px;
                                }
                            }
                        }

                        >p {
                            float: left;
                            color: #757575;
                            line-height: 26px;
                            cursor: pointer;

                            span {
                                display: inline-block;
                                // margin-right:6px;
                            }
                        }

                        p+p {
                            padding-left: 53px;
                            transition: all 1s;
                        }

                        .price {
                            float: left;
                            margin-left: 53px;
                            position: relative;
                            top: 0px;
                            left: 0px;

                            // &:hover>div {
                            //     display: block;
                            // }

                            .reward {
                                .btnBG();
                                width: 22px;
                                height: 22px;
                                border-radius: 50%;
                                overflow: hidden;
                                cursor: pointer;
                            }


                        }
                    }

                    .commentHistoryList {
                        clear: both;
                        // overflow: hidden;
                        // height:500px;
                        .commentHistory_box {
                            padding-top: 20px;
                            clear: both;

                            .commentHistory_parentbox {}

                            .comment_input {
                                padding-left: 67px;
                            }

                            .commentHistory_childrenbox {
                                padding-top: 20px;
                                clear: both;
                                padding-left: 67px;

                                .xiu_small {
                                    width: 24px;
                                    height: 24px;

                                    img {
                                        transform: scale(0.16);
                                    }
                                }

                                .xiu_box {
                                    right: -300px !important
                                }


                            }

                            .el-pagination {
                                padding-left: 67px;
                                clear: both;
                            }

                            // .el-pagination{ 
                            clear: both;
                            padding-left:67px;

                            .el-pagination.is-background .el-pager li {
                                background: transparent !important;
                                border: 0px !important;
                                margin: 0px 0px;
                                min-width: 0px;
                            }

                            .el-pagination.is-background .el-pager li:not(.disabled).active {
                                color: #00a1d6;
                            }

                            .el-pagination.is-background .el-pager li:not(.disabled):hover {
                                color: #00a1d6 !important;
                            }

                            .el-pagination.is-background .btn-next {
                                background: transparent !important;
                                border: 0px !important;
                                margin: 0px 0px;
                                min-width: 0px;
                            }

                            // }
                            >p {
                                clear: both;
                                padding-left: 67px;
                                font-size: 12px;
                                color: #999999;

                                span {
                                    color: #00a1d6;
                                    cursor: pointer;
                                }
                            }

                            .commentInput_box {
                                width: 91% !important;
                                margin-left: 0px !important;

                            }

                            .user_xiu {
                                float: left;
                                padding-right: 14px;
                                position: relative;
                                top: 0px;
                                left: 0px;

                                .xiu_box {
                                    width: 341px;
                                    height: 226px;
                                    padding: 19px;
                                    box-sizing: border-box;
                                    background-color: #ffffff;
                                    // display: none;
                                    position: absolute;
                                    right: -274px;
                                    z-index: 99999;
                                    top: -230px;
                                    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.21);
                                    border-radius: 4px;

                                    >div {
                                        background: #646464;
                                        overflow: hidden;
                                        width: 305px;
                                        height: 192px;

                                        .xiu_max {
                                            width: 150px;
                                            height: 190px;
                                            float: left;
                                            margin: 1px 0px 0px 1px;
                                            position: relative;

                                            >img {
                                                width: 150px !important;
                                            }
                                        }

                                        ul {
                                            float: left;
                                            width: 154px;
                                            height: 192px;
                                            // display: none;
                                            background: url("../assets/xiuIcon_bg.png")no-repeat;
                                            background-position: center;

                                            li {
                                                float: left;
                                                width: 17px;

                                                height: 17px;
                                                margin: 0px 0px 2px 2px;
                                                // background:red;
                                            }
                                        }
                                    }
                                }
                            }

                            .commentInformation {
                                font-size: 14px;
                                float: left;

                                .userName {
                                    color: #666666;
                                }

                                .commentText {
                                    color: #333333;
                                    padding: 14px 0px;
                                }

                                .commentText+p {
                                    color: #b8b8b8;
                                    font-size: 12px;
                                    line-height: 17px;

                                    em {
                                        font-style: normal;
                                        cursor: pointer;
                                        font-size: 14px;
                                    }

                                    em+em {
                                        padding-left: 26px;

                                        span {
                                            padding-right: 3px;
                                            cursor: pointer;
                                        }

                                        .icon-huifu {
                                            padding-right: 0px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .video_comment {
                        .commentTitle {
                            border-bottom: 1px solid #ebedf3;
                            padding-top: 54px;

                            span {
                                display: block;
                                color: #ff8a00;
                                font-size: 14px;
                                padding-bottom: 20px;
                                width: 28px;
                                margin-left: 23px;
                                border-bottom: 1px solid #ff8a00;
                            }
                        }

                        .comment_input {
                            clear: both;
                            padding-top: 20px;

                            .user_xiu {
                                float: left;
                                position: relative;

                                .xiu_box {
                                    display: none;
                                }
                            }

                            .commentInput_box {
                                float: left;
                                width: 86%;
                                margin-left: 15px;
                                padding-bottom: 18px;
                                border-bottom: 1px solid #ebedf3;
                                position: relative;

                                .notlogin {
                                    width: 748px;
                                    position: absolute;
                                    top: 0px;
                                    left: 0px;
                                    text-align: center;
                                    line-height: 82px;
                                    background: #f4f4f4;
                                    height: 82px;
                                    color: #b8b8b8;

                                    >a {
                                        color: #00a1d6;
                                    }
                                }

                                .emojis {
                                    width: 100px;
                                    height: 30px;
                                    float: left;

                                    >p {
                                        img {
                                            float: left;
                                            padding-right: 6px;
                                        }

                                        span {
                                            float: left;
                                        }

                                        cursor: pointer;
                                    }

                                    >div {
                                        display: none;
                                    }

                                    &:hover>div {
                                        display: block
                                    }
                                }

                                textarea {
                                    width: 100%;
                                    height: 82px;
                                    text-indent: 10px;
                                    padding-top: 8px;
                                    box-sizing: border-box;
                                    clear: both;
                                    margin-bottom: 20px;
                                    resize: none;
                                    border: solid 1px #ebedf3;
                                }

                                >div {
                                    p {
                                        float: left;
                                        color: #999999;
                                    }

                                    position: relative;
                                    top:0px;
                                    left:0px;

                                    .emotion-box {
                                        position: absolute;
                                        top: 20px;
                                        left: 0px;
                                        width: 400px;
                                        z-index: 999;
                                        background: white;
                                    }
                                }


                                button {
                                    float: right;
                                    border-radius: 6px;
                                    .btnBG();
                                    width: 75px;
                                    height: 36px;
                                }
                            }
                        }
                    }
                }

                .content_right {
                    float: right;
                    width: 313px;

                    .video_up {
                        margin: 27px 0px 13px 0px;
                        height: 50px;
                        cursor: pointer;
                        .user_info {    
                            float: left;

                            p {
                                color: #333333;
                                font-size: 16px;
                                line-height: 50px;
                                text-indent: 6px;
                            }

                            button {
                                margin-top: 7px;
                                cursor: pointer;
                                .btnBG();
                                width: 78px;
                                height: 25px;
                                border-radius: 13px;
                            }
                        }
                    }

                    >ul {
                        clear: both;

                        li {
                            overflow: hidden;

                            .cover_box {
                                position: relative;
                                top: 0px;
                                left: 0px;
                                width: 161px;
                                height: 128px;
                                border-radius: 9px;
                                overflow: hidden;
                                float: left;

                                img {
                                    width: 161px;
                                    height: 128px;
                                }

                                span {
                                    position: absolute;
                                    bottom: 9px;
                                    right: 7px;
                                    color: white;
                                    font-size: 12px;
                                }
                            }

                            .video_introduce {
                                width: 144px;
                                float: right;

                                >p {
                                    font-size: 14px;
                                    color: #333333;
                                    line-height: 14px;
                                    height: 87px;
                                    line-height: 24px;
                                }

                                span,
                                em {
                                    font-size: 12px;
                                    color: #999999;
                                    display: block;
                                    font-style: normal;
                                    margin-top: 4px;
                                }
                            }
                        }

                        li+li {
                            margin-top: 15px;

                        }
                    }
                }
            }
        }
    }

    .reportModal {
        width: 100%;
        height: 100vh;
        background: rgba(0, 0, 0, 0.6);
        position: fixed;
        z-index: 9999;
        top: 0px;
        left: 0px;

        >div {
            width: 382px;
            height: 240px;
            background-color: #ffffff;
            border-radius: 4px;
            position: fixed;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            margin: auto;

            textarea {
                width: 333px;
                resize: none;
                height: 158px;
                padding-top: 2px;
                text-indent: 4px;
                background-color: #f4f4f4;
                border-radius: 2px;
                border: solid 1px #e7e7e7;
                margin: 28px 25px 12px 25px;
            }

            button {
                width: 55px;
                height: 25px;
                background-color: #ff8a00;
                border-radius: 13px;
                color: #ffffff;
                font-size: 12px;
                margin-left: 126px;
            }

            button+button {
                background-color: #00a1d6;
                margin-left: 21px;
            }
        }
    }
</style>